import { createStitches } from '@stitches/react';
import { colors, darkColors } from './tokens/colors';
import { textStyles } from './tokens/text-styles';
import { ShadowStyle, shadowStyles } from './tokens/shadow-styles';
import { breakpoints } from './tokens/breakpoints';

export type UITheme = 'light' | 'dark';
export type UIThemeOptions = UITheme | 'system';

const space: Record<string, string> = {
  0: '2px',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '36px',
  10: '40px',
  11: '44px',
  12: '48px',
  14: '56px',
  20: '80px',
  24: '96px',
  36: '144px',
} as const;

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors,
    space,
  },
  media: {
    ...breakpoints,
  },
  utils: {
    textStyle: (style: keyof typeof textStyles) => {
      return textStyles[style];
    },

    shadowStyle: (style: ShadowStyle) => {
      const shadow = shadowStyles[style];
      return {
        boxShadow: shadow.light,
        [darkThemeSelector]: {
          boxShadow: shadow.dark,
        },
      };
    },

    p: (value: string) => ({
      padding: value,
    }),
    pl: (value: string) => ({
      paddingLeft: value,
    }),
    pr: (value: string) => ({
      paddingRight: value,
    }),
    pt: (value: string) => ({
      paddingTop: value,
    }),
    pb: (value: string) => ({
      paddingBottom: value,
    }),
    px: (value: string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: (value: string) => ({
      margin: value,
    }),
    ml: (value: string) => ({
      marginLeft: value,
    }),
    mr: (value: string) => ({
      marginRight: value,
    }),
    mt: (value: string) => ({
      marginTop: value,
    }),
    mb: (value: string) => ({
      marginBottom: value,
    }),
    mx: (value: string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: string) => ({
      marginTop: value,
      marginBottom: value,
    }),
  },
});

export const darkTheme = createTheme({
  colors: darkColors,
});

export const darkThemeClassName: string = darkTheme.className;

export const darkThemeSelector: string = `.${darkTheme.className} &`;

function getSystemTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
}

export function setSystemTheme() {
  setTheme(getSystemTheme());
}

export function setTheme(mode: UITheme) {
  if (mode === 'dark') {
    document.body.classList.add(darkTheme.className);
  } else {
    document.body.classList.remove(darkTheme.className);
  }
}

const UI_THEME_KEY = 'jw-ui-theme';

export function setUiThemePreference(theme: UIThemeOptions) {
  localStorage.setItem(UI_THEME_KEY, theme);
}

export function getUiThemePreference(): UIThemeOptions {
  return (localStorage.getItem(UI_THEME_KEY) as UIThemeOptions) || 'light';
}

type styledParams = Parameters<typeof styled>;

export function namedStyled(name: string, ...params: styledParams) {
  const result = styled(...params);
  result.displayName = name;
  return result;
}
