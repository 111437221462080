import React from 'react';
import { observer } from 'mobx-react';
import { StoryCollection } from '@core/models/catalog/featured-release';
import { MarkSimple } from '@naan/primitives/text';
import { Link, useNavigate } from 'react-router-dom';
import { collectionDetailPath } from 'components/nav/path-helpers';
import { namedStyled } from '@naan/stitches.config';
import { Image } from '@naan/primitives/image';

const Wrapper = namedStyled('CollectionCardContainer', 'div', {
  display: 'flex',
  flexDirection: 'column',

  '& > .cover': {
    width: '100%',
    marginBottom: 16,
    textDecoration: 'none',
    '& > img': {
      width: '100%',
      borderRadius: 8,
      aspectRatio: '375 / 211',
      backgroundColor: '$black-alpha-10',
    },
  },

  '& > .content': {
    '& > .description': {
      marginBottom: 20,
      '& > .title': {
        display: 'block',
        textDecoration: 'none',
        textStyle: 'medium-heading',
        color: 'inherit',
        marginBottom: 8,
      },
    },

    '& > .little-thumbs': {
      display: 'flex',
      gap: 8,
      '& > img': {
        width: 48,
        ratio: 1,
        borderRadius: 4,
        aspectRatio: 1,
      },
    },
  },

  variants: {
    featured: {
      true: {
        '@medium': {
          borderTop: '1px solid $colors$black-alpha-10',
          paddingTop: 16,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 24,
          '& > .cover': {
            gridRow: '1 / span 2',
            gridColumn: '2 / 3',
            marginTop: 4,
          },
        },
        '@extraLarge': {
          gridTemplateColumns: '4fr 8fr',
        },
      },
    },
  },
});

export const CollectionCard = observer(
  ({
    collection,
    featured,
  }: {
    collection: StoryCollection;
    featured?: boolean;
  }) => {
    const navigate = useNavigate();

    if (!collection) return null;

    const permalink = collectionDetailPath(collection.slug);

    return (
      <Wrapper
        onClick={() => {
          navigate(permalink);
        }}
        featured={featured}
      >
        <Link className="cover" to={permalink}>
          <Image src={collection.illustrationUrl} alt={collection.title} />
        </Link>
        <div className="content">
          <div className="description">
            <Link className="title" to={permalink}>
              <MarkSimple source={collection.title} />
            </Link>
            <MarkSimple source={collection.preview} />
          </div>
          <div className="little-thumbs">
            {collection.stories.map(story => (
              <Image
                key={story.slug}
                src={story.listImageUrl}
                alt={story.title}
                style={{ backgroundColor: story.themeColor }}
              />
            ))}
          </div>
        </div>
      </Wrapper>
    );
  }
);
