import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { useNavigate } from 'react-router-dom';
import { learnHomePath, subscribePath } from 'components/nav/path-helpers';
import { presentPurchaseDisabledAlert } from 'components/account/account-cta/purchase-flow-disabled';
// import { Story } from '@core/models/story-manager/story';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { styled } from '@naan/stitches.config';
import { LockSmallIcon } from '@naan/icons/lock-icon';
import { Button } from '@naan/primitives/button';
import { useCurrentStory } from 'components/story-detail/current-story-context';

import __, { translateWithoutDefault } from '@core/lib/localization';
import { LocaleCode } from '@utils/util-types';
import { ArrowLeftSmallIcon } from '@naan/icons/arrow-left-icon';
import { ReturnNavState } from 'components/nav/return-nav-state';
// import { unwidow } from '@utils/string-utils';

const Wrapper = styled('div', {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px 16px 32px 16px',
  maxWidth: '600px',
  margin: '0 auto',

  '& > .title': {
    textStyle: 'small-heading',
    display: 'flex',
    marginBottom: '12px',
    alignItems: 'center',
    gap: '4px',
  },

  '& > .buttons': {
    display: 'flex',
    marginTop: '16px',
    '& > * + *': {
      marginLeft: 8,
    },
  },
});

const storyLockedActionsBlurb = (l2: LocaleCode) => {
  // switch (l2) {
  //   case 'en':
  //     // return __(
  //     //   ``,
  //     //   'storyLockedActionsBlurb.en'
  //     // );
  //     return ''; // todo
  //   default:
  //     return __(
  //       'You can listen to all Radio Ambulante stories and access the transcripts for free at radioambulante.org',
  //       'youCanListenToAllRadioAmbulanteStories'
  //     );
  // }
  return translateWithoutDefault(`cms:storyLockedActionsBlurb:${l2}`);
};
export const StoryLockedActions = observer(() => {
  const navigate = useNavigate();
  const story = useCurrentStory();

  if (!story.locked) {
    return null;
  }

  const { userManager, l2 } = AppFactory.root;
  const canGoBack = ReturnNavState.canPopFromStoryDetail();

  // const ActionButton = (props: ButtonProps) => (
  //   <Responsive
  //     renderDefault={() => <Button css={{ flex: 1 }} {...props} />}
  //     renderMediumAndUp={() => <Button {...props} />}
  //   />
  // );

  return (
    <CenterColumnLayout backgroundColor="$colors$red-50">
      <Wrapper>
        <h3 className="title">
          <span className="icon">
            <LockSmallIcon />
          </span>
          {__('Subscribers only', 'subscribersOnly')}
        </h3>
        <p>{storyLockedActionsBlurb(l2)}</p>
        {/* <p>
          {
            unwidow(
              __(
                // todo: refactor to a cms: loco managed string once merged to develop
                `Enjoy 100+ amazing stories from 20 countries, plus over 200 Soundbites, with Jiveworld's unique study system, and understand Spanish like a native speaker.`,
                'lockedStorySubscribeBlubSpanish'
              )
            )
            // 'You can listen to all Radio Ambulante stories and access the transcripts for free at radioambulante.org',
            // 'youCanListenToAllRadioAmbulanteStories'
          }
        </p> */}
        <div className="buttons">
          <Button
            onClick={() =>
              canGoBack ? navigate(-1) : navigate(learnHomePath())
            }
            /*  onClick={() => navigate(learnHomePath({ fragment: 'free' }))} */
            presentation="grayLight"
            label={__('Go back', 'goBack')}
            leftIcon={<ArrowLeftSmallIcon />}
          />
          {userManager.purchaseFlowDisabled ? (
            <Button
              onClick={presentPurchaseDisabledAlert}
              presentation="teal"
              label={__('Learn more', 'learnMore')}
            />
          ) : (
            <Button
              onClick={() => navigate(subscribePath())}
              presentation="teal"
              label={__('Subscribe', 'subscribe')}
            />
          )}
        </div>
      </Wrapper>
    </CenterColumnLayout>
  );
});
