import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { ItemButtonsContainer } from '../item-buttons-container';
import { usePlayerModel } from 'player/views/player-model-context';
import {
  ChapterReviewModel,
  NotationStatus,
  statusIs,
} from 'vocab-review/chapter-review-model';
import { VocabListItemHead } from './item-head';
import { ItemStatusIcon } from '../item-status-icon';
import { paletteKey } from '../../../../../study/views/study-palette';

const ItemWrapper = styled('div', {
  padding: '8px 0 12px',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '$space$4',
  position: 'relative',
  transition: 'box-shadow .3s',

  '&.focus': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
    borderRadius: 16,
    outline: '1px solid $colors$globalBlack-alpha-06',
    boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
    [darkThemeSelector]: {
      boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.40)',
    },
  },

  '& > .inner': {
    display: 'grid',
    gridTemplateColumns: '1fr 36px',
    '& .bookmark-icon': {
      display: 'flex',
      alignItems: 'center',
      '&.blocked': {
        pointerEvents: 'none',
      },
    },
  },
});

export const LearnItem = observer(
  ({
    notation,
    status,
  }: {
    notation: ClientNotation;
    status: NotationStatus;
  }) => {
    const model = usePlayerModel() as ChapterReviewModel;
    const focused = model.currentNotationId === notation.id;

    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (model.currentNotationId === notation.id) {
          if (!statusIs(status, 'pending')) {
            model.waitAndMoveToNextReviewItem();
          }
        } else {
          model.setCurrentNotationId(notation.id);
        }
      },
      [model, notation.id, status]
    );

    return (
      <ItemWrapper
        className={classNames('notation-learn-controls', {
          focus: focused,
        })}
        onClick={handleClick}
      >
        <div className="inner">
          <VocabListItemHead notation={notation} />
          <div className="bookmark-icon">
            <ItemStatusIcon notation={notation} status={status} />
          </div>
        </div>
        <ItemButtonsContainer
          notation={notation}
          focused={focused}
          status={status}
        />
      </ItemWrapper>
    );
  }
);
