import * as React from 'react';
import { observer } from 'mobx-react';
// import { ExcerptData } from 'tikka/client/catalog-types';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import classNames from 'classnames';
import { AnswerMarkdown } from '@naan/primitives/text/soundbite-question-markdown';
import { AppFactory } from 'app/app-factory';
import { AnswerState, SoundbiteModel } from 'soundbite/models/soundbite-model';
import { scrollToAnswer } from './scroll-to-answer-button';
import { elementIds } from 'components/dom-utils/element-ids';
import { FeedbackButton } from 'components/ui/feedback/feedback-button';
import { Text } from '@naan/primitives/text';

import { __, __f } from '@core/lib/localization';

const arrow = (
  <svg
    width={28}
    height={48}
    viewBox="0 0 28 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4996 6.00002C16.4996 6.00002 22.5 22 15.6833 45.8009M15.6833 45.8009C15.6833 45.8009 22 42 26.5 34.5M15.6833 45.8009C15.6833 45.8009 12.5 39 7.49956 33.742"
      stroke="#383838"
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
  </svg>
);

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$white',
  background: '$$backgroundColor',
  padding: '0 16px',
  transition: 'opacity 0.3s',

  '&.teaser': {
    $$backgroundColor: '$colors$black-alpha-06',
    height: 128,
    position: 'relative',

    '& .message': {
      textStyle: 'small-heading',
    },
    '& .arrow': {
      position: 'absolute',
      bottom: 0,
      left: 'calc(50% - 14px)',
      zIndex: 3,
      '& path': {
        stroke: '$colors$gray-800',
      },
    },
  },

  '&.disabled': {
    $$backgroundColor: '$colors$black-alpha-06',
  },

  '&.unrevealed': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
  },

  '&.revealed': {
    borderBottom: '8px solid $gray-75',
  },

  '.playing &': {
    opacity: 0.5,
  },

  '& .heading': {
    unset: 'all',
    textStyle: 'small-heading',
    color: '$colors$green-600',
    padding: '16px 0',
  },

  // this protects against mistaps on the play button when the Reveal button is close to it
  '& .tap-guard': {
    height: 10,
    marginBottom: -10,
    position: 'sticky',
    bottom: 0,
  },

  '@widerThanScript': {
    maxWidth: 600,
    margin: '0 auto 56px',
    borderRadius: 16,
    // boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    shadowStyle: 'small',
    '&.teaser, &.disabled': {
      boxShadow: 'none',
    },
    '&.revealed': {
      marginBottom: '0',
      borderBottom: 'none',
    },
  },
});

const revealAnswer = () => {
  AppFactory.soundbiteModel.revealAnswer();
  requestAnimationFrame(() => {
    scrollToAnswer();
  });
};

const revealLabelFn = __f('Reveal answer', 'revealAnswer');

const FeedbackButtonWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBlock: 16,
  borderTop: '1px solid $black-alpha-10',
  marginTop: 24,
});

const SoundbiteFeedbackButton = ({ model }: { model: SoundbiteModel }) => (
  <FeedbackButtonWrapper>
    <FeedbackButton
      label={__(
        'Give feedback on this Soundbite',
        'giveFeedbackOnThisSounrbite'
      )}
      dialogContext={
        <>
          <Text textStyle="body-bold">{model.story.title}</Text>
          <Text textStyle="body">
            {__('Soundbite', 'soundbite')}: {model.soundbite.title}
          </Text>
        </>
      }
      metadata={{
        type: 'soundbite',
        story: model.story,
        soundbite: model.soundbite, // needed?
        context: model.soundbite.title,
      }}
    />
  </FeedbackButtonWrapper>
);

export const Answer = observer(({ model }: { model: SoundbiteModel }) => {
  const excerpt = model.excerptData;
  const { answerState: state } = AppFactory.soundbiteModel;

  if (!excerpt.explanation) {
    return null;
  }

  return (
    <>
      <Wrapper
        id={elementIds.ANSWER_CARD}
        className={classNames({
          teaser: state === AnswerState.initial,
          disabled: state === AnswerState.disabled,
          unrevealed: state !== AnswerState.revealed,
          revealed: state === AnswerState.revealed,
        })}
      >
        {state === AnswerState.initial && (
          <>
            <span className="message">
              {__('Tap play to listen', 'tapPlayToListen')}
            </span>
            <span className="arrow">{arrow}</span>
          </>
        )}

        {state === AnswerState.disabled && (
          <div>
            <Button
              presentation={'grayLight'}
              disabled
              label={revealLabelFn()}
            />
          </div>
        )}
        {state === AnswerState.unrevealed && (
          <div>
            <Button
              presentation={'green'}
              onClick={revealAnswer}
              label={revealLabelFn()}
            />
            <div className="tap-guard"></div>
          </div>
        )}

        {state === AnswerState.revealed && (
          <>
            <h2 className="heading">{__('Answer', 'answer')}</h2>
            <AnswerMarkdown source={excerpt.explanation} />
            <SoundbiteFeedbackButton model={model} />
          </>
        )}
      </Wrapper>
    </>
  );
});
