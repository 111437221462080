import * as React from 'react';
import * as Onboarding from 'components/ui/onboarding/components';
import { darkThemeSelector, keyframes, styled } from 'naan/stitches.config';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  FloatingArrow,
  arrow,
  FloatingPortal,
  FloatingFocusManager,
  useDismiss,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { OnboardingDialogContext } from './onboarding-dialogs';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import __ from '@core/lib/localization';

const TipWrapper = styled('div', {
  width: 'min(100vw - 32px, 400px)',
  $$radius: '12px',
  $$maxWidth: '360px',
  $$maxHeight: '800px',
  backgroundColor: '$white',
  borderRadius: '$$radius',
  // boxShadow: `0px 1px 4px $colors$black-alpha-30`,
  shadowStyle: 'small',
  position: 'fixed',
  top: 'calc(50% + (var(--sat) / 2) - (var(--sab) / 2))',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 'min(calc(100% - 32px - var(--sat) - var(--sab)), $$maxHeight)',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 3,
  paddingTop: 16,

  '&:focus': { outline: 'none' },
});

const showKeyframes = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const Overlay = styled('div', {
  backgroundColor: '$colors$globalBlack-alpha-10',
  [darkThemeSelector]: {
    backgroundColor: '$colors$globalBlack-alpha-20',
  },
  position: 'fixed',
  inset: 0,
  zIndex: 2,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${showKeyframes} 0.25s cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

type Props = {
  showPopover: boolean;
  // onResolvePopover: (action: Onboarding.OnboardingDialogAction) => void;
  onResolvePopover: (value: boolean /*irrelevant*/) => void;
  renderAnchor: (options: {
    ref: (node: HTMLElement) => void;
    getProps: (userProps?: React.HTMLProps<Element>) => Record<string, unknown>;
  }) => React.ReactNode;
  renderBody: () => React.ReactNode;
  withOverlay?: boolean;
  dismissed?: boolean;
  dismissable?: boolean;
};

const NonDismissedOnboardingPopover = ({
  showPopover,
  onResolvePopover,
  renderAnchor,
  renderBody,
  withOverlay = true,
  dismissable = false,
}: Omit<Props, 'dismissed'>) => {
  const arrowRef = React.useRef<HTMLImageElement>(null);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: showPopover,
    onOpenChange: () => onResolvePopover(true /*irrelevant*/),
    placement: 'top',
    middleware: [
      offset(18),
      shift({
        padding: 8,
      }),
      flip(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const dismiss = useDismiss(context, {
    enabled: dismissable,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context);

  const forceHide = useSwitch2(false);

  // const onSkip = React.useCallback(() => {
  //   // we need a bit of a dance to handle the speed control tip which needs to delay restarting
  //   // the audio until after the skip tip alert is also dismisssed
  //   forceHide.on();
  //   runSkipTipsAlert()
  //     .then(() => onResolvePopover('skipTips'))
  //     .catch(bugsnagNotify);
  // }, [onResolvePopover, forceHide]);

  return (
    <>
      {renderAnchor({ ref: refs.setReference, getProps: getReferenceProps })}
      {showPopover && !forceHide.value ? (
        <FloatingPortal>
          {withOverlay ? <Overlay /> : null}
          <FloatingFocusManager context={context}>
            {isMounted && (
              <TipWrapper
                ref={refs.setFloating}
                style={{
                  ...floatingStyles,
                  ...transitionStyles,
                }}
                {...getFloatingProps()}
              >
                {/* <Onboarding.Header>
                  <Onboarding.SkipTipsButton onClick={onSkip}>
                    {__('Skip tips', 'skipTips')}
                  </Onboarding.SkipTipsButton>
                </Onboarding.Header> */}
                <OnboardingDialogContext.Provider value={onResolvePopover}>
                  {renderBody()}
                </OnboardingDialogContext.Provider>
                <Onboarding.ButtonsContainer>
                  <Onboarding.GotItButton
                    label={__('Got it', 'gotIt')}
                    onClick={() => onResolvePopover(true /*irrelevant*/)}
                  />
                </Onboarding.ButtonsContainer>
                <FloatingArrow
                  ref={arrowRef as any}
                  context={context}
                  fill="var(--colors-white)"
                  stroke="rgba(0,0,0,.15)"
                  strokeWidth={1}
                  width={20}
                  height={10}
                />
              </TipWrapper>
            )}
          </FloatingFocusManager>
        </FloatingPortal>
      ) : null}
    </>
  );
};

export const OnboardingPopover = ({ dismissed = false, ...props }: Props) => {
  if (dismissed) {
    return <>{props.renderAnchor({ ref: () => {}, getProps: () => ({}) })}</>;
  }
  return <NonDismissedOnboardingPopover {...props} />;
};
