import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { PlayerViewController } from '../player-view-controller';
import { createLogger } from '@app/logger';

const logger = createLogger('progress-bar');

const ProgressbarWrapper = styled('div', {
  '--progress': '50%',
  background: 'rgba(0, 0, 0, 0.15)',
  width: 160,
  height: 8,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',

  '& > .progress': {
    background: '$colors$globalWhite',
    height: '100%',
    width: 'var(--progress)',
  },
});

export const ProgressBar = ({ player }: { player: PlayerViewController }) => {
  logger.debug('progress-bar', 'render', 'player', player?.playerName);
  const barRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const bar = barRef.current;
    logger.debug(
      'progress-bar',
      'useEffect',
      'player',
      player?.playerName,
      'hasVideo',
      !!player?.videoElement,
      'hasBar',
      !!bar
    );

    const update = () => {
      if (!player.mounted) {
        logger.debug(
          'progress-bar',
          'update',
          'player is unmounted - terminating loop'
        );
        return;
      }

      const video = player.videoElement;

      if (video) {
        const duration = video.duration;
        const currentTime = video.currentTime;
        const progress = (currentTime / duration) * 100;
        // logger.debug(
        //   'progress-bar',
        //   'update',
        //   'progress',
        //   progress,
        //   'bar',
        //   !!bar
        // );

        if (bar) {
          bar.style.setProperty('--progress', `${progress}%`);
        }
      } else {
        logger.debug('progress-bar', 'update', 'no video');
      }

      window.requestAnimationFrame(update);
    };

    window.requestAnimationFrame(update);

    return () => {
      logger.debug('progress-bar', 'useEffect', 'cleanup');
    };
  }, [player, player.videoElement]);

  return (
    <ProgressbarWrapper ref={barRef}>
      <div className="progress" />
    </ProgressbarWrapper>
  );
};
