import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { ClockIcon } from '@naan/icons/clock-icon';
// import { Story } from '@core/models/story-manager';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { AppFactory } from '@app/app-factory';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { Story } from '@core/models/story-manager';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';

import __ from '@core/lib/localization';

const StoryActionsBar = styled('div', {
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
  backgroundColor: '$$backgroundColor',
  padding: '0 0 calc(16px + var(--sab))',
  boxShadow: '0 -0 16px 16px $$backgroundColor',
  marginBottom: 'calc(var(--sab) * -1)',
});

const StoryActionStyles = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 8,
});

// type ButtonProps = React.ComponentProps<typeof Button>;

const StoryActionNotNull = observer(({ story }: { story: Story }) => {
  const { unstarted, unqueued } = story;

  const handleBeginStudying = React.useCallback(() => {
    const { offline } = AppFactory.root;
    if (offline && !story.isCachedMemoized) {
      presentSimpleAlert(
        <>
          {__(
            'You appear to be offline. Please try again later.',
            'offlineTryLater'
          )}
        </>
      );
    } else {
      story.progress.beginStudying();
      window.setTimeout(() => {
        storyDetailUiModel.executeAutoscroll();
      }, 200);
    }
  }, [story]);

  if (!unstarted) {
    return null;
  }

  // const ActionButton = (props: ButtonProps) => (
  //   <Responsive
  //     renderDefault={() => <Button css={{ flex: 1 }} {...props} />}
  //     renderMediumAndUp={() => <Button {...props} />}
  //   />
  // );

  return (
    <StoryActionsBar>
      <CenterColumnLayout narrow>
        <StoryActionStyles>
          <Button
            label={__('Begin story', 'beginStory')}
            presentation={'globalWhite'}
            size="large"
            onClick={handleBeginStudying}
            alignment="unset"
            fullWidth
          />
          <Button
            label={__('Later', 'later')}
            presentation={'whiteLighten'}
            size="large"
            leftIcon={unqueued ? <ClockIcon /> : <CheckmarkIcon />}
            onClick={() => story.progress.toggleStudyLater()}
            alignment="unset"
            fullWidth
          />
        </StoryActionStyles>
      </CenterColumnLayout>
    </StoryActionsBar>
  );
});

export const StoryAction = observer(() => {
  const story = useCurrentStory();

  if (story.locked) {
    return null;
  }

  return <StoryActionNotNull story={story} />;
});
