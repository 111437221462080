import * as React from 'react';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import {
  RelistenCircleExtraExtraLargeIcon,
  RelistenCircleIcon,
} from '@naan/icons/relistened-icon';
import { ChapterCatalogData } from '@core/models/catalog';
import { ChapterReviewOverflowMenu } from './chapter-review-overflow-menu';
import { PlayerMode } from '@common/misc-types';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';

import __ from '@core/lib/localization';
import { useChapterRowUiContext } from './chapter-row-ui-model';
import autoAnimate from '@formkit/auto-animate';
import classNames from 'classnames';

const CardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  // justifyContent: 'center',
  // padding: 16,
  // paddingTop: 40,
  backgroundColor: '$colors$gray-700',
  [darkThemeSelector]: {
    backgroundColor: '$colors$gray-50',
  },
  borderRadius: 16,
  transition:
    'background-color 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  overflow: 'auto',

  '& .title-container': {
    cursor: 'default',
    userSelect: 'none',
    padding: '16px 20px 16px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&.open': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .card-title': {
      textStyle: 'small-caps',
      color: '$globalWhite',
    },
    '& .icon': {
      color: '$globalWhite',
    },
  },
});

const InnerWrapper = styled('div', {
  $$textColor: '$colors$globalWhite',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  paddingTop: 36,
  borderRadius: 16,
  backgroundColor: '$colors$gray-700',
  [darkThemeSelector]: {
    backgroundColor: '$colors$gray-100',
  },
  color: '$$textColor',
  transition:
    'background-color 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  overflow: 'auto',
  '& .heading': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40,

    '& svg': {
      width: 56,
      height: 56,
    },
    '& .title': {
      textStyle: 'small-heading',
      margin: '2px 0',
    },
    '& .duration': {
      textStyle: 'small-text',
      color: '$gray-100',
      [darkThemeSelector]: {
        color: '$gray-400',
      },
    },
  },

  '& > .buttons': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },

  '@medium': {
    paddingTop: 44,
    '& .heading': {
      marginBottom: 48,
    },
  },
});

const OverflowMenuWrapper = styled('div', {
  position: 'absolute',
  top: 4,
  right: 8,
});

export const ChapterReviewCard = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const model = useChapterRowUiContext();
    const open = model.currentStage === 'study';
    const parent = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    const navigateToStudy = useNavigateToStudy();

    const handleContinueClick = React.useCallback(() => {
      navigateToStudy(
        chapter,
        PlayerMode.FLUENT_LISTEN,
        chapter.currentMillis,
        false /* viaDetail */
      );
    }, [chapter, navigateToStudy]);

    const handleDoneClick = React.useCallback(() => {
      chapter.story.lastChapter.resumeStudy();
    }, [chapter]);

    const atStart = chapter.position === 1 && chapter.currentMillis === 0;

    return (
      <CardWrapper ref={parent}>
        {open ? null : (
          <div
            className={classNames('title-container', { open })}
            onClick={() => {
              if (model.currentStage !== 'study') {
                model.setCurrentStage('study');
              }
            }}
          >
            <span className="card-title">
              {__('Relistening', 'relistening')}
            </span>
            <span className="icon">
              <RelistenCircleIcon />
            </span>
          </div>
        )}
        {open ? (
          <InnerWrapper>
            <OverflowMenuWrapper className="something">
              <ChapterReviewOverflowMenu chapter={chapter} inReviewMode />
            </OverflowMenuWrapper>

            <div className="heading">
              <RelistenCircleExtraExtraLargeIcon />
              <span className="title">{__('Relistening', 'relistening')}</span>
              <span className="duration">{chapter.prettyDuration}</span>
            </div>
            <div className="buttons">
              <Button
                onClick={handleDoneClick}
                label={__('Done', 'done')}
                size="extraLarge"
                presentation={'whiteLighten'}
                fullWidth
              />
              <Button
                onClick={handleContinueClick}
                label={
                  atStart ? __('Begin', 'begin') : __('Continue', 'continue')
                }
                size="extraLarge"
                presentation={'teal'}
                fullWidth
                // style={
                //   midListen
                //     ? ({
                //         '---currentProgress': `${chapter.currentPercentage}%`,
                //       } as any)
                //     : null
                // }
              />
            </div>
          </InnerWrapper>
        ) : null}
      </CardWrapper>
    );
  }
);
