import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { HStack, VStack } from 'naan/primitives/stack';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';
import { PlainMarkdown, Text } from 'naan/primitives/text';
import { Controller } from 'react-hook-form';
import { Textarea } from 'naan/primitives/input';
import { DatePicker } from 'components/ui/date-picker';
import { useCurrentAssignment } from '../assignment/current-assignment-context';
import { styled } from 'naan/stitches.config';
import { useComboForm } from 'common/hooks/use-comboform';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { assignmentSchema } from 'core/lib/validation-rules';
import { Assignment } from 'core/models/user-manager';
import { AppFactory } from 'app/app-factory';
import { Image } from '@naan/primitives/image';

import __ from 'core/lib/localization';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';

type ClassDetailModalProps = {
  unitSlug: string;
  classId: string;
  onClose: () => void;
  buttonLabel?: string;
  assignment?: Assignment;
};

const MAX_LENGTH = 250;

const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const Thumb = styled(Image, {
  width: 64,
  height: 64,
  borderRadius: 4,
});

const TextAreaWrapper = styled('div', {
  position: 'relative',
  '& > textarea': {
    paddingRight: '$10',
  },
  '& > .counter': {
    position: 'absolute',
    bottom: '$1',
    right: '$1',
    padding: '4px 6px',
    borderRadius: '$space$2',
    textStyle: 'small-text',
    color: '$gray-400',
    backgroundColor: '$gray-50',
    opacity: '0.75',
    '&.danger': {
      color: '$red-500',
    },
  },
});

type FormInput = { dueDate: string; details: string };

export const ClassDetailModal = observer(
  ({
    onClose,
    classId,
    unitSlug,
    buttonLabel = __('Assign to class', 'assignToClass'),
    assignment: propsAssignment,
  }: ClassDetailModalProps) => {
    let assignment = propsAssignment;
    const { assignment: contextAssignment } = useCurrentAssignment();
    if (!assignment) {
      assignment = contextAssignment;
    }
    const store = AppFactory.root;
    const { managedL2Classrooms } = store.userManager.accountData;

    const defaultValues = assignment ?? { dueDate: '', details: '' };
    // console.log('assignment', assignment);

    const form = useComboForm<FormInput>({
      // mode: 'onChange',
      // resolver: yupResolver(ValidationSchema) as any,
      validationSchema: assignmentSchema(),
      defaultValues: defaultValues,
      shouldUnregister: false,
    });

    const formRef = React.useRef<HTMLFormElement>(null);
    const story = store.storyManager.story(unitSlug);

    const { handleSubmit, register, control, errors, watch } = form;

    const detailsWatch = watch('details');

    const classroom = managedL2Classrooms.find(c => c.id === classId);

    const submitHandler = handleSubmit(async values => {
      try {
        await classroom?.createAssignmentWithProps(unitSlug, values);
        track(events.classroom.manageStudentAssignment, {
          classroom: classroom?.label,
        });
        onClose();
        AppFactory.toastService.open({
          type: 'success',
          message: __('Assigned', 'assigned'),
        });
        /// this is hack to prevent a weird bug that was disabling scrolling on body
        document.body.style.pointerEvents = '';
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        onClose();
      }
    });

    if (!classroom) {
      return null;
    }

    return (
      <>
        <Dialog.Container
          open={true}
          onDismiss={onClose}
          css={{ $$maxWidth: '480px' }}
        >
          <Form onSubmit={submitHandler} ref={formRef}>
            <Dialog.CloseButton />
            <Dialog.Heading>
              {__('Assign to class', 'assignToClass')}
            </Dialog.Heading>
            <Dialog.Body>
              <VStack>
                {story ? (
                  <>
                    <VSpacer size={4} />
                    <HStack align={'center'}>
                      <Thumb src={story.listImageUrl} alt="" />
                      <HSpacer size={4} />
                      <Text textStyle="small-heading">
                        <PlainMarkdown source={story?.title} />
                      </Text>
                    </HStack>
                    <VSpacer size={4} />
                  </>
                ) : null}
                <VSpacer size={4} />
                <HStack>
                  <VStack>
                    <Text>{classroom.label}</Text>
                    {/* TODO: factor this up with class-selector-modal */}
                    <Text textStyle={'small-text'} color={'textSecondary'}>
                      {__(
                        {
                          one: '%{count} assignment',
                          other: '%{count} assignments',
                        },
                        'assignmentsCount',
                        {
                          count: classroom.assignmentCount,
                        }
                      )}
                      {', '}
                      {__(
                        {
                          one: '%{count} student',
                          other: '%{count} students',
                        },
                        'studentsCount',
                        {
                          count: classroom.studentCount,
                        }
                      )}
                    </Text>
                  </VStack>
                </HStack>
                <VStack>
                  <VSpacer size={4} />

                  <label htmlFor="dueDate">
                    <Text textStyle="small-text" color="textSecondary">
                      {__('Due date', 'dueDate')}
                    </Text>
                  </label>
                  <VSpacer size={2} />
                  <Controller
                    control={control}
                    name="dueDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        placeholder={__('Add a due date', 'addADueDate')}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <VSpacer size={4} />
                  <label htmlFor="details">
                    <Text textStyle="small-text" color="textSecondary">
                      {__(
                        'Instructions for students',
                        'instructionsForStudents'
                      )}
                    </Text>
                  </label>
                  <VSpacer size={2} />
                  <TextAreaWrapper>
                    <Textarea id="details" {...register('details')} rows={4} />
                    <span
                      className={cx([
                        'counter',
                        { danger: detailsWatch?.length > MAX_LENGTH },
                      ])}
                    >
                      {MAX_LENGTH - (detailsWatch?.length ?? 0)}
                    </span>
                  </TextAreaWrapper>
                  <CatchAllFormErrors errors={errors} />
                </VStack>
              </VStack>
            </Dialog.Body>
            <Dialog.ButtonsContainer>
              <Dialog.Button
                label={__('Cancel', 'cancel')}
                presentation="cancel"
                type="button"
                onClick={onClose}
              />
              <Dialog.Button label={buttonLabel} presentation="teal" />
            </Dialog.ButtonsContainer>
          </Form>
        </Dialog.Container>
      </>
    );
  }
);

// export const ClassDetailModal = observer(
//   ({ onClose, unitSlug, classId, buttonLabel }: ClassDetailModalProps) => {
//     return (
//       <ClassDetailBody
//         onClose={onClose}
//         unitSlug={unitSlug}
//         classId={classId}
//         buttonLabel={buttonLabel}
//       />
//     );
//   }
// );
