import * as React from 'react';

import { observer } from 'mobx-react';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { Button } from '@naan/primitives/button';
import { PlainMarkdown } from '@naan/primitives/text/markdown/plain-markdown';
import { icons } from 'components/soundbites/soundbites-icons';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { ChevronRightSmallIcon } from '@naan/icons/chevron-right-icon';
import { Link } from 'react-router-dom';
import { calendarSoundbitePath } from 'components/nav/path-helpers';
import { UnitCatalogData } from '@core/models/catalog';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { getGatekeeperStatus } from 'components/soundbites/soundbite-gatekeeper';
import { LockIcon } from '@naan/icons/lock-icon';
import { Story } from '@core/models/story-manager';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';

import __ from '@core/lib/localization';

const ModalContainer = styled(DialogHtmlContainer, {
  width: 'min( 600px, calc(100% - 32px) )',
  borderRadius: 12,
  overflow: 'hidden',
});

const ContentWrapper = styled('div', {
  overflowY: 'auto',

  '& > .soundbite-rows': {
    // borderTop: '1px solid $gray-100',
    '& > * + *': {
      borderTop: '1px solid $gray-100',
    },
  },
});

const HeadingWrapper = styled('div', {
  background: '$orange-600',
  [darkThemeSelector]: {
    background: '$orange-400',
  },
  color: '$globalWhite',
  padding: '$2 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&  .left': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&  .title': {
      marginLeft: '$4',
      textStyle: 'small-heading',
    },
    '&  .count': {
      marginLeft: '$2',
      textStyle: 'small-text',
    },
  },
  '&  .right': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&  .button': {
      marginLeft: 'auto',
      marginRight: '$1',
    },
  },
});

const PartHeadingWrapper = styled('div', {
  zIndex: 1,
  borderTop: '1px solid $gray-100',
  position: 'sticky',
  top: 0,
  textStyle: 'small-heading',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  background: '$gray-25',
  paddingLeft: '$space$4',
});

const TitleHeadingWrapper = styled('div', {
  zIndex: 1,
  borderBottom: '1px solid $gray-100',
  position: 'sticky',
  top: 0,
  textStyle: 'body-bold',
  height: 48,
  display: 'flex',
  alignItems: 'center',
  background: '$gray-25',
  paddingLeft: '$space$4',

  '&.has-part': {
    top: 40,
  },

  '& > .number': {
    width: 24,
    color: '$textSecondary',
  },
  '& > .title': {},
});

const RowContainer = styled(Link, {
  $$hoverColor: '$coors$white',
  all: 'unset',
  cursor: 'pointer',
  marginLeft: 40,
  padding: '16px 16px 16px 0',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    left: -40,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: -1,
    backgroundColor: '$$hoverColor',
  },

  '&.disabled': {
    cursor: 'default',
  },

  '&:not(.disabled):hover': {
    // @frankharrison I didn't know what to do for row hover color
    $$hoverColor: '$colors$gray-25',
  },

  '& > .inner': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: 12,

    '& > .icon svg': {
      height: 32,
      width: 32,
    },

    '& > .title': {
      display: 'flex',
      flexDirection: 'column',
      textStyle: 'small-text-bold',
      '& > .category': {
        color: '$textSecondary',
        textStyle: 'small-caps',
      },
    },

    '& .chevron': {
      color: '$gray-400',
    },

    '& .lock': {
      color: '$gray-800',
    },
  },
});

const SoundbiteRow = observer(
  ({
    soundbite,
    completed,
    teacherMode,
  }: {
    soundbite: Soundbite;
    completed: boolean;
    teacherMode: boolean;
  }) => {
    const iconSet = icons[soundbite.category as keyof typeof icons] ?? null;
    const SmallIcon = iconSet?.small ?? null; // beware, the 'null' here was barfing unless guarded below
    // const completed = soundbite.completed;
    const status = getGatekeeperStatus({ soundbite });

    const disabled = status !== 'UNLOCKED' || teacherMode;

    return (
      <RowContainer
        className={disabled ? 'disabled' : ''}
        key={soundbite.slug}
        to={calendarSoundbitePath(soundbite.slug)}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
          }
        }}
      >
        <div className="inner">
          <div className="icon">{SmallIcon && <SmallIcon />}</div>
          <div className="title">
            <span className="category">{soundbite.localizedCategoryLabel}</span>
            {soundbite.title}
          </div>
          <div className="right">
            {completed ? (
              <div className="checkmark">
                <CheckmarkCircleIcon color="var(--colors-green-500)" />
              </div>
            ) : teacherMode ? null : status === 'UNLOCKED' ? (
              <span className="chevron">
                <ChevronRightSmallIcon />
              </span>
            ) : (
              <span className="lock">
                <LockIcon />
              </span>
            )}
          </div>
        </div>
      </RowContainer>
    );
  }
);

const StoryUnit = observer(
  ({
    unit,
    multiUnit,
    completedSlugs,
    teacherMode,
  }: {
    unit: UnitCatalogData;
    multiUnit: boolean;
    completedSlugs: string[];
    teacherMode: boolean;
  }) => {
    /// For some reason, keyvbordService doesn't work while this modal is opened

    return (
      <>
        {unit.chaptersWithSoundbites.map((chapter, index) => (
          <React.Fragment key={unit.slug + '_' + chapter.position}>
            {multiUnit && index === 0 ? (
              <PartHeadingWrapper>{unit.partLabel}</PartHeadingWrapper>
            ) : null}
            <TitleHeadingWrapper className={multiUnit ? 'has-part' : ''}>
              <span className="number">{chapter.position}</span>
              <PlainMarkdown className="title" source={chapter.title} />
            </TitleHeadingWrapper>
            {
              <div className="soundbite-rows">
                {chapter.chapterSoundbites.map(soundbite => {
                  const completed = completedSlugs.includes(soundbite.slug);
                  return (
                    <SoundbiteRow
                      soundbite={soundbite}
                      completed={completed}
                      teacherMode={teacherMode}
                      key={soundbite.slug}
                    />
                  );
                })}
              </div>
            }
          </React.Fragment>
        ))}
      </>
    );
  }
);

export const SoundbitesModal = observer(
  ({
    story,
    completedSlugs,
    studentName,
    onDismiss,
  }: {
    story: Story;
    completedSlugs: string[];
    studentName?: string;
    onDismiss: () => void;
  }) => {
    const teacherMode = !!studentName;
    const titlePrefix = studentName ? `${studentName}: ` : '';

    const count = completedSlugs.length;

    React.useEffect(() => {
      track(events.classroom.openStudentSoundbitesModal, {
        story: story.title,
      });
    }, [story.title]);

    /// For some reason, keyboardService doesn't work while this modal is opened

    return (
      <ModalContainer onDismiss={onDismiss}>
        <HeadingWrapper>
          <div className="left">
            <span className="title">
              {titlePrefix + __('Soundbites', 'soundbites')}
            </span>
            <span className="count">
              {__('%{count} complete', 'nComplete', {
                count,
              })}
            </span>
          </div>
          <div className="button">
            <Button
              onClick={onDismiss}
              label={__('Close', 'close')}
              presentation="whiteTransparent"
            />
          </div>
        </HeadingWrapper>
        <ContentWrapper>
          {story.units.map(unit => (
            <StoryUnit
              unit={unit}
              multiUnit={story.multiUnit}
              completedSlugs={completedSlugs}
              teacherMode={teacherMode}
              key={unit.slug}
            ></StoryUnit>
          ))}
        </ContentWrapper>
      </ModalContainer>
    );
  }
);
