import React, { useState, useRef } from 'react';
import { useSwitch } from '@naan/hooks/use-switch';
import { usePopper } from 'react-popper';
import { styled, darkThemeSelector } from '@naan/stitches.config';

const TipWrapper = styled('div', {
  $$backgroundColor: '$colors$yellow-200',
  $$borderColor: '$colors$yellow-300',
  [darkThemeSelector]: {
    $$backgroundColor: '$colors$yellow-600',
    $$borderColor: '$colors$yellow-600',
  },
  // boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.2)',
  shadowStyle: 'medium',
  borderRadius: '8px',
  backgroundColor: '$$backgroundColor',
  textAlign: 'center',
  border: '1px solid $$borderColor',
  zIndex: '1001',

  '& > .tip-content': {
    textStyles: 'body',
    color: 'rgba(00,00,00, .7)',
    padding: '12px 16px',
  },

  '& > .tip-arrow': {
    position: 'absolute',
    width: '10px',
    height: '10px',

    '& svg': {
      width: '18px',
      height: '10px',

      '&.arrow-stroke': {
        fill: '$$borderColor',
      },

      '.arrow-fill': {
        fill: '$$backgroundColor',
      },
    },
  },

  "&[data-popper-placement^='bottom'] > .tip-arrow": {
    left: '0',
    top: '-10px',
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },

  "&[data-popper-placement^='right'] > .tip-arrow": {
    left: '-14px',
    '& svg': {
      transform: 'rotate(90deg)',
    },
  },
  "&[data-popper-placement^='left'] > .tip-arrow": {
    right: '-6px',
    '& svg': {
      transform: 'rotate(-90deg)',
    },
  },
  "&[data-popper-placement^='top'] > .tip-arrow": {
    left: '-10px',
    bottom: '-10px',
  },

  variants: {
    presentation: {
      white: {
        $$backgroundColor: '$colors$white',
        $$borderColor: '$colors$white',
      },
    },
  },
});

const TipArrow = () => (
  <svg viewBox="0 0 18 10" fill="none">
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <path d="M9 10L1 1H17L9 10Z" className="arrow-stroke" />
      </g>
      <path d="M9 10L0 0H18L9 10Z" className="arrow-stroke" />
      <path d="M9 8.5L1.33594 0H16.6719L9 8.5Z" className="arrow-fill" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-3"
        y="-2"
        width="24"
        height="17"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="18" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

type TipWrapperProps = React.ComponentProps<typeof TipWrapper>;
type ForwardedWrapperTips = Pick<TipWrapperProps, 'presentation'>;

type TooltipProps = {
  placement:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  tipContent: React.ReactNode;
  showTip: boolean;
  offset?: [number, number];
} & ForwardedWrapperTips;

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  placement = 'top',
  tipContent = null,
  showTip = false,
  offset = [0, 10],
  children,
  ...props
}) => {
  const buttonRef = useRef(null);
  const popperRef = useRef(null);
  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState(null);

  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: offset,
          },
        },
      ],
    }
  );

  return (
    <>
      <span ref={buttonRef}>{children}</span>
      {showTip ? (
        <TipWrapper
          ref={popperRef}
          style={styles.popper}
          {...props}
          {...attributes.popper}
        >
          <div ref={setArrowRef} style={styles.arrow} className="tip-arrow">
            <TipArrow />
          </div>
          <div className="tip-content">{tipContent}</div>
        </TipWrapper>
      ) : null}
    </>
  );
};

export const UncontrolledTooltip: React.FC<
  React.PropsWithChildren<
    {
      event: 'click' | 'hover';
      initialShow?: boolean;
    } & Omit<TooltipProps, 'showTip'>
  >
> = ({ event = 'click', initialShow = false, children, ...props }) => {
  const [show, { on, off, toggle }] = useSwitch(initialShow);

  const triggerProps =
    event === 'hover'
      ? {
          onMouseEnter: on,
          onMouseOut: off,
        }
      : {
          onClick: toggle,
        };
  return (
    <>
      {show ? (
        <div
          style={{
            position: 'fixed',
            background: 'rgba(0, 0,0 ,.05)',
            inset: 0,
          }}
          onClick={off}
        ></div>
      ) : null}
      <Tooltip showTip={show} {...props}>
        <span {...triggerProps}>{children}</span>
      </Tooltip>
    </>
  );
};
